import { useEffect } from 'react';

import type { CustomWindow, ENV } from '~/types';

/**
 * Hook that injects environment variables into the window object for client-side access.
 * This hook is essential for the getApiUrl utility to work on the client side.
 *
 * Required setup:
 * 1. Must be used in conjunction with envLoader in your route
 * 2. Should be called with env from useLoaderData
 *
 * @param env - Environment variables object containing API_HOST and API_BASE_URL
 *
 * @example Setup in route:
 * ```
 * export const loader = envLoader;
 * export default function Route() {
 *   const { env } = useLoaderData<typeof loader>();
 *   useEnv(env);
 *   // ... rest of component
 * }
 * ```
 *
 * @warning This modifies the global window object. Should only be used at the route level.
 */
export function useEnv(env: ENV) {
  useEffect(() => {
    if (!env?.API_HOST || !env?.API_BASE_URL) return;

    (window as unknown as CustomWindow).API_HOST = env.API_HOST;
    (window as unknown as CustomWindow).API_BASE_URL = env.API_BASE_URL;
    (window as unknown as CustomWindow).VAPID_PUBLIC_KEY = env.VAPID_PUBLIC_KEY;
    (window as unknown as CustomWindow).GOOGLE_OAUTH_URL = env.GOOGLE_OAUTH_URL;
    (window as unknown as CustomWindow).DISCORD_OAUTH_URL =
      env.DISCORD_OAUTH_URL;
    (window as unknown as CustomWindow).TWITCH_OAUTH_URL = env.TWITCH_OAUTH_URL;
    (window as unknown as CustomWindow).SURVIVAL_GAME_INITIAL_SECONDS =
      env.SURVIVAL_GAME_INITIAL_SECONDS;
  }, [env]);
}
